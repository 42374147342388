@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

.banner-home-mobile {
    background-image: linear-gradient(
            rgba(0, 30, 100, 0.5),
            rgba(0, 30, 100, 0.5)
        ),
        url('/public/images/banner-aviso-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 621px;
}

.banner-home {
    background-image: linear-gradient(
            rgba(0, 30, 100, 0.5),
            rgba(0, 30, 100, 0.5)
        ),
        url('/public/images/banner-aviso.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 621px;
}

.title {
    font-family: 'Archivo', sans-serif;
}

.title-campanhas {
    font-size: 32px;
}

.title-card {
    font-size: 56px;
}

.card-campanha {
    height: 288px;
    /* width: 25rem; */
}

.title-regulamento {
    font-size: 40px;
}

.radius-button {
    border-radius: 155px;
}

.bg-login {
    background-image: linear-gradient(
            rgba(0, 30, 100, 0.5),
            rgba(0, 30, 100, 0.5)
        ),
        url('/public/images/bg-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    min-height: 621px;
}

.bg-login-mobile {
    background-image: linear-gradient(
            rgba(0, 30, 100, 0.5),
            rgba(0, 30, 100, 0.5)
        ),
        url('/public/images/bg-login-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    min-height: 621px;
}

table {
    border-collapse: separate;
    border-spacing: 10px 20px;
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 20px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e0cbcb;
        border-radius: 100vh;
        border: 1px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #a0b1c0;
    }
}
